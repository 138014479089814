import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const DashBoard = lazy(() => import("./Dashboard"));
const LineOfCredit = lazy(() => import("./LineOfCredits"));
const AccountManager = lazy(() => import("./AccountManager"));
const UsersManagement = lazy(() => import("./UserManagement"));
const Warehouse = lazy(() => import("./warehouse"));
const Report = lazy(() => import("./report"));
const Inventory = lazy(() => import("./Inventory"));
const TopUpData = lazy(() => import("./TopUpData"));
const AdminManager = lazy(() => import("./AdminManager"));
const Transactions = lazy(() => import("./transactions"));
const RoleManager = lazy(() => import("./Role"));
const MerchantManager = lazy(() => import('./MerchantManager'))

const App = ({ match }) => (
  <div className="">
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path={`${match.url}dashboard`} component={DashBoard} />
        <Route path={`${match.url}topup`} component={TopUpData} />
        <Route path={`${match.url}transactions`} component={Transactions} />
        <Route
          path={`${match.url}merchant-manager`}
          component={MerchantManager}
        />
        <Route path={`${match.url}users-management`} component={UsersManagement} />
        <Route path={`${match.url}admin-manager`} component={AdminManager} />
        <Route path={`${match.url}line-of-credit`} component={LineOfCredit} />
        <Route path={`${match.url}warehouse`} component={Warehouse} />
        <Route path={`${match.url}report`} component={Report} />
        <Route path={`${match.url}inventory`} component={Inventory} />
        <Route
          path={`${match.url}account-manager`}
          component={AccountManager}
        />
        <Route path={`${match.url}role`} component={RoleManager} />
      </Switch>
    </Suspense>
  </div>
);

export default App;
