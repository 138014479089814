import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  GET_PROFILE
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  user: {},
  token: localStorage.getItem("token")
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: payload
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        token: payload.token,
        user: payload
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: payload
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        user: {},
        token: null,
        initURL: "/",
        loader: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: payload
      };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: payload
      };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: payload
      };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: payload
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case GET_PROFILE: {
      return {
        ...state,
        user: payload
      };
    }
    default:
      return state;
  }
};
