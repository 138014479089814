import _ from "lodash";
import { configs } from "constants/Configs";
import { SIGNOUT_USER_SUCCESS } from "constants/ActionTypes";
import api from "./index";

export default function createApiAuthMiddle({ dispatch, getState }) {
  return next => action => {
    const type = _.get(action, "type");
    const isAuthRequestApi = _.get(action, "isAuthRequestApi");
    const authToken = _.get(getState(), "auth.token");
    if (type === SIGNOUT_USER_SUCCESS) {
      api.changeHeaders({
        [configs.APIs_HEADERS.AUTHORIZATION]: ""
      });
    } else if (isAuthRequestApi === true && authToken !== "") {
      api.changeHeaders({
        [configs.APIs_HEADERS.AUTHORIZATION]: authToken
      });
    }
    return next(action);
  };
}
