import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";

import createApiAuthMiddle from "../drivers/middleware";
import rootSaga from "../sagas";
import createRootReducer from "../reducers";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [
  thunk,
  sagaMiddleware,
  routeMiddleware,
  createApiAuthMiddle
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  // add redux logger in dev mode
  if (process.env.NODE_ENV === "development") {
    const { logger } = require("redux-logger");
    // TODO debug redux
    // middlewares.push(logger);
  }
  const store = createStore(
    createRootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers");
      store.replaceReducer(nextRootReducer(history));
    });
  }
  return store;
}
export { history };
