import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
// Add this import:

// Wrap the rendering in a function:
const render = () => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <React.StrictMode>
      <NextApp />
    </React.StrictMode>
    ,
    document.getElementById("root")
  );
};

//Temp remove error resize observer
//TODO: FIx it
window.addEventListener('error', e => {
  if (e.message === 'ResizeObserver loop limit exceeded' || e.message == "ResizeObserver loop completed with undelivered notifications.") {
      const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
      );
      const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
      );
      if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
      }
      if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
      }
  }
});

// Do this once
registerServiceWorker();

// Render once
render(NextApp);
