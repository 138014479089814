import {
  GET_PACKAGE,
  GET_PACKAGE_SUCCESS,
  GET_LIST_MERCHANT,
  GET_LIST_MERCHANT_SUCCESS
} from "constants/ActionTypes";

export const fetchPackage = dataRequest => {
  return {
    type: GET_PACKAGE,
    dataRequest
  };
};

export const fetchPackageSuccess = dataResult => {
  return {
    type: GET_PACKAGE_SUCCESS,
    payload: dataResult
  };
};

export const fetchMerchant = dataRequest => {
  return {
    type: GET_LIST_MERCHANT,
    dataRequest
  };
};

export const fetchMerchantSuccess = dataResult => {
  return {
    type: GET_LIST_MERCHANT_SUCCESS,
    payload: dataResult
  };
};
