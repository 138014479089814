import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Button, Tooltip } from "antd";
import _, { isEmpty } from "lodash";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "../../util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
  NAV_STYLE_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const { SubMenu } = Menu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname, user } = this.props;
    const selectedKeys = `main/${pathname.substr(1)}`;
    const defaultOpenKeys = `main/${selectedKeys.split("/")[1]}`;

    return (
      <Auxiliary>
        <div style={{ padding: "10%" }}>
          <SidebarLogo />
        </div>
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            {navStyle !== NAV_STYLE_MINI_SIDEBAR && (
              <div style={{ display: "flex", gap: "2%" }}>
                {_.get(user, "role") === "merchant" ? (
                  <b>
                    {isEmpty(user?.subscription) ? (
                      <em style={{ color: "red" }}>0 VND</em>
                    ) : (
                      <em
                        style={{ color: "green" }}
                      >{`${new Intl.NumberFormat().format(
                        user?.subscription?.balance
                      )} VND`}</em>
                    )}
                  </b>
                ) : (
                  <></>
                )}
              </div>
            )}
            {/* <AppsNavigation /> */}
          </div>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="main/dashboard" className="side-bar-background">
              <Link to="/dashboard" className="side-icon">
                <i className="icon icon-dasbhoard" />
                <IntlMessages id="sidebar.dashboard" />
              </Link>
            </Menu.Item>
            {_.get(user, "role") === "admin" ||
            _.get(user, "role") === "moderator" ||
            _.get(user, "role") === "merchant" ||
            _.get(user, "role") === "accounter" || _.get(user, "role") === "user" ? (
              <SubMenu
                key="main/transactions"
                className={`${this.getNavStyleSubMenuClass(
                  navStyle
                )} side-bar-background`}
                title={
                  <span className="side-icon">
                    <i className="icon icon-refer" />
                    <IntlMessages id="sidebar.transactions" />
                  </span>
                }
              >
                {_.get(user, "role", "") === "admin" || _.get(user, "role") === "merchant" || _.get(user, "role") === "accounter" ? (
                  <Menu.Item
                    key="main/transactions/packages-transactions"
                    className="side-bar-background"
                  >
                    <Link
                      to="/transactions/packages-transactions"
                      className="side-icon"
                    >
                      <i className="icon icon-card" />
                      <IntlMessages id="sidebar.transactions.packages" />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}

                {_.get(user, "role", "") === "admin" ||
                _.get(user, "role", "") === "merchant" ||
                _.get(user, "role") === "moderator" ||
                _.get(user, "role") === "accounter" || _.get(user, "role") === "user" ? (
                  <Menu.Item
                    key="main/transactions/sim-number-transactions"
                    className="side-bar-background"
                  >
                    <Link
                      to="/transactions/sim-number-transactions"
                      className="side-icon"
                    >
                      <i className="icon icon-card" />
                      <IntlMessages id="sidebar.transactions.sim.numbers" />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {/* {_.get(user, "role", "") === "admin" || _.get(user, "role") === "moderator" ? (
                  <Menu.Item
                    key="main/transactions/package-adjust-history"
                    className="side-bar-background"
                  >
                    <Link
                      to="/transactions/package-adjust-history"
                      className="side-icon"
                    >
                      <i className="icon icon-hotel-booking" />
                      <IntlMessages id="sidebar.transactions.packages-adjustment" />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )} */}
              </SubMenu>
            ) : (
              <></>
            )}

            {/* start warehouse */}
            {_.get(user, "role", "") === "admin" || _.get(user, "role") === "moderator" ? (
              <SubMenu
                key="main/warehouse"
                className={`${this.getNavStyleSubMenuClass(
                  navStyle
                )} side-bar-background`}
                title={
                  <span className="side-icon">
                    <i className="icon icon-home" />
                    <IntlMessages id="sidebar.warehouse" />
                  </span>
                }
              >
                <Menu.Item
                  key="main/inventory/buy-card"
                  className="side-bar-background"
                >
                  <Link to="/inventory/buy-card" className="side-icon">
                    <i className="icon icon-card" />
                    <IntlMessages id="sidebar.merchant.card" />
                  </Link>
                </Menu.Item>
                {/* <Menu.Item
                  key="main/warehouse/data"
                  className="side-bar-background"
                >
                  <Link to="/warehouse/data" className="side-icon">
                    <i className="icon icon-culture-calendar" />
                    <IntlMessages id="sidebar.warehouse.data" />
                  </Link>
                </Menu.Item> */}
                <Menu.Item
                  key="main/warehouse/suppliers"
                  className="side-bar-background"
                >
                  <Link to="/warehouse/suppliers" className="side-icon">
                    <i className="icon icon-culture-calendar" />
                    <IntlMessages id="sidebar.warehouse.suppliers" />
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="main/warehouse/package-lists"
                  className="side-bar-background"
                >
                  <Link to="/warehouse/package-lists" className="side-icon">
                    <i className="icon icon-culture-calendar" />
                    <IntlMessages id="sidebar.warehouse.packageLists" />
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              <></>
            )}
            {/* end warehouse */}

            {/* start helper */}
            {/* end helper */}

            {/* start report */}
            {_.get(user, "role", "") === "admin" ? (
              <SubMenu
                key="main/report"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span className="side-icon">
                    <i className="icon icon-data-display" />
                    <IntlMessages id="sidebar.report" />
                  </span>
                }
              >
                <Menu.Item key="main/report/top-up">
                  <Link to="/report/top-up" className="side-icon">
                    <i className="icon icon-callout" />
                    <IntlMessages id="sidebar.report.topUp" />
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="main/report/sim">
                  <Link to="/report/sim" className="side-icon">
                    <i className="icon icon-cards-list-view" />
                    <IntlMessages id="sidebar.report.sim" />
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="main/report/sales">
                  <Link to="/report/sales" className="side-icon">
                    <i className="icon icon-filter-circle" />
                    <IntlMessages id="sidebar.report.sales" />
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="main/report/kits">
                  <Link to="/report/kits" className="side-icon">
                    <i className="icon icon-donut" />
                    <IntlMessages id="sidebar.report.kits" />
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="main/report/report-quantity">
                  <Link to="/report/report-quantity" className="side-icon">
                    <i className="icon icon-files" />
                    <IntlMessages id="sidebar.report.data" />
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="main/report/card">
                  <Link to="/report/card" className="side-icon">
                    <i className="icon icon-card" />
                    <IntlMessages id="sidebar.report.card" />
                  </Link>
                </Menu.Item> */}
              </SubMenu>
            ) : (
              <></>
            )}
            {/* end report */}
            {_.get(user, "role", "") === "admin" ||
            _.get(user, "role") === "moderator" ||
            _.get(user, "role") === "accounter" ? (
              <>
                <Menu.Item
                  key="main/merchant-manager"
                  className="side-bar-background"
                >
                  <Link to="/merchant-manager" className="side-icon">
                    <i className="icon icon-custom-view" />
                    <IntlMessages id="sidebar.merchant" />
                  </Link>
                </Menu.Item>
              </>
            ) : (
              <></>
            )}

            {_.get(user, "role", "") === "admin" ||
            _.get(user, "role") === "moderator" ? (
              <Menu.Item
                key="main/users-management"
                className="side-bar-background"
              >
                <Link to="/users-management" className="side-icon">
                  <i className="icon icon-auth-screen" />
                  <IntlMessages id="sidebar.userManagement" />
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}

            {_.get(user, "role", "") === "admin" ||
            _.get(user, "role") === "accounter" ||
            _.get(user, "role") === "moderator" ? (
              <SubMenu
                key="main/line-of-credit"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span className="side-icon">
                    <i className="icon icon-crm" />
                    <IntlMessages id="sidebar.credit" />
                  </span>
                }
              >
                <Menu.Item key="main/line-of-credit/balance-history">
                  <Link
                    to="/line-of-credit/balance-history"
                    className="side-icon"
                  >
                    <i className="icon icon-picker" />
                    <IntlMessages id="sidebar.balance" />
                  </Link>
                </Menu.Item>
                {_.get(user, "role", "") === "admin" ||
                _.get(user, "role") === "moderator" ? (
                  <Menu.Item key="main/line-of-credit/waiting-list">
                    <Link
                      to="/line-of-credit/waiting-list"
                      className="side-icon"
                    >
                      <i className="icon icon-profile2" />
                      <IntlMessages id="sidebar.approval" />
                    </Link>
                  </Menu.Item>
                ) : (
                  <></>
                )}
              </SubMenu>
            ) : (
              <></>
            )}

            {/* end user management */}

            {/* start role management */}
            {/* {_.get(user, "role", "") === "admin" && (
              <SubMenu
                key="main/role"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span className="side-icon">
                    <i className="icon icon-user-o" />
                    <IntlMessages id="sidebar.userRoleManagement" />
                  </span>
                }
              >
                <Menu.Item key="main/role/role-management">
                  <Link to="/role/role-management" className="side-icon">
                    <i className="icon icon-profile2" />
                    <IntlMessages id="sidebar.userRoleManagement.role" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/role/user-role-management">
                  <Link to="/role/user-role-management" className="side-icon">
                    <i className="icon icon-reset-password" />
                    <IntlMessages id="sidebar.userRoleManagement.user" />
                  </Link>
                </Menu.Item>
              </SubMenu>
            )} */}
            {/* start account management */}
            <SubMenu
              key="main/account-manager"
              className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span className="side-icon">
                  <i className="icon icon-setting" />
                  <IntlMessages id="sidebar.accountManager" />
                </span>
              }
            >
              <Menu.Item key="main/account-manager/profile">
                <Link to="/account-manager/profile" className="side-icon">
                  <i className="icon icon-profile2" />
                  <IntlMessages id="sidebar.accountManager.profile" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/account-manager/change-password">
                <Link
                  to="/account-manager/change-password"
                  className="side-icon"
                >
                  <i className="icon icon-reset-password" />
                  <IntlMessages id="sidebar.accountManager.changePassword" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/account-manager/authentication">
                <Link
                  to="/account-manager/authentication"
                  className="side-icon"
                >
                  <i className="icon icon-reset-password" />
                  <IntlMessages id="sidebar.accountManager.authentication" />
                </Link>
              </Menu.Item>
            </SubMenu>
            {/* end account management */}
            {/* </MenuItemGroup> */}
          </Menu>
          {/* </CustomScrollbars> */}
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { user } = auth;
  return { user, navStyle, themeType, locale, pathname };
};
export default connect(mapStateToProps, {})(SidebarContent);
