import React, {memo} from "react";
import { connect } from "react-redux";
import { Button, Form, Input, message } from "antd";
import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import api from "../appRedux/drivers/index";
import { login } from "../constants/api";

class SignIn extends React.Component {
  formRef = React.createRef();

  handleSubmit = () => {
    this.formRef.current.validateFields().then(async (values) => {
        const formData = new FormData();
        formData.append('username', values?.username);
        formData.append('password', values?.password);
        const { data } = await api.post(login, formData);
        this.props.showAuthLoader();
        this.props.userSignIn(data.data);
      
    }).catch (err => {
      message.error(err.error.message);
      console.log(err)
    }) 
  }


  componentDidMount() {
    this.formRef.current.setFieldsValue({
      username: '',
      password: ''
    });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.token) {
      this.props.history.push("/");
    }
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content" style={{ padding: "0" }}>
              <div className="gx-app-logo-wid">
                <img
                  style={{ width: "1024px" }}
                  src={require("assets/images/logo-signin.png")}
                  alt="Neature"
                />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                className="gx-signin-form gx-form-row0"
              >
                <Form.Item name="username" rules= {[
                      {
                        required: true,
                        message: "Please input your Username!"
                      }
                    ]}>
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item name="password" rules= {[
                      { required: true, message: "Please input your Password!" }
                    ]}>
                  <Input.Password type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : null}
            {showMessage ? message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

// const withUseFormHook = (Component) => {
//   return props => {
//       const form = Form.useForm();
//       return <Component {...props} {...form} />
//   }       
// };


const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, token } = auth;
  return { loader, alertMessage, showMessage, token };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
})(memo(SignIn));
