
const v1 = "/api/v1";

export const login = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/login`;

export const createUser = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/signup`;

export const getListUser = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/get-list`;

export const deleteUser = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/delete`;

export const editUser = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/edit`;

export const getMe = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/get-profile`;

export const updateProfile = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/get-profile`;

export const changePassword = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/edit-password`;

export const getTransaction = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobile/data/transactions`;

export const getPackage = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobile/data/packages`;

export const updatePackage = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobileData/update`;

export const deletePackage = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobileData/delete`;

export const createPackage = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobileData/create`;

export const getTablesData = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/dashboard/sales`;

export const exportExcel = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/sales/total`;

export const getUser = id =>
  `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/get-detail/${id}`;

export const getDataGroup = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobile/data/group`;


export const getListTopUp = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/mobile/data`;



export const getPackageDetails = `${process.env.REACT_APP_API_ENDPOINT}${v1}/mobile/data/packages`;

export const getSuppliers = `${process.env.REACT_APP_API_ENDPOINT}${v1}/suppliers`;

export const getMerchant = `${process.env.REACT_APP_API_ENDPOINT}${v1}/merchants`;

export const getCircleCharts = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/dashboard/revenue`;

export const getBarLineCharts = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/dashboard/trending`;

export const getKitsBarLineCharts = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/dashboard/trending-kit`;

export const getKitsCircleCharts = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/dashboard/revenue-kit`;

export const getSecretToken = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/2fa/token`;

export const getConfirmToken = `${process.env.REACT_APP_API_ENDPOINT}${v1}/user/2fa/confirm-token`

export const getMerchantData = `${process.env.REACT_APP_API_ENDPOINT}${v1}/reports/summary`;

export const getC90NReports = `${process.env.REACT_APP_API_ENDPOINT}${v1}/sms/report/quantity`;

export const getListKits = `${process.env.REACT_APP_API_ENDPOINT}${v1}/sim/kit/list`;

export const getDetailKits = `${process.env.REACT_APP_API_ENDPOINT}${v1}/sim/kit/detail`;

export const getPartnerList = `${process.env.REACT_APP_API_ENDPOINT}${v1}/sim/partner/list`;

export const getSimPackagesList = `${process.env.REACT_APP_API_ENDPOINT}${v1}/sim/packages/list`;

export const getExportExcelSimKits = `${process.env.REACT_APP_API_ENDPOINT}${v1}/sim/kit/report`;

export const getAddBalance = `${process.env.REACT_APP_API_ENDPOINT}${v1}/balanceticket/add`;

export const getBalanceHistory = `${process.env.REACT_APP_API_ENDPOINT}${v1}/historybalance/list`;

export const getBalanceReviewList = `${process.env.REACT_APP_API_ENDPOINT}${v1}/balanceticket/list`;

export const getBalanceAcceptance = `${process.env.REACT_APP_API_ENDPOINT}${v1}/balanceticket/review`;

export const getPackageAdjustmentHistory = `${process.env.REACT_APP_API_ENDPOINT}${v1}/log/mobile/data`
