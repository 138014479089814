import {
  GET_PACKAGE_SUCCESS,
  GET_LIST_MERCHANT_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  packages: [],
  merchants: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PACKAGE_SUCCESS: {
      return {
        ...state,
        packages: action.payload
      };
    }

    case GET_LIST_MERCHANT_SUCCESS: {
      return {
        ...state,
        merchants: action.payload
      };
    }

    default:
      return state;
  }
};
