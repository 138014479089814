import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchPackageSuccess, fetchMerchantSuccess } from "../actions/Options";
import _ from "lodash";
import { GET_PACKAGE, GET_LIST_MERCHANT } from "constants/ActionTypes";
import { getPackage, getMerchant } from "constants/api";
import { fetchError } from "../actions/Common";
import api from "../drivers";

async function getPackageRequest(params) {
  try {
    const { data } = await api.get(getPackage, { ...params, limit: 5000 });
    const setData = _.map(data.data, item => ({
      key: item.name,
      value: item.id
    }));
    setData.unshift({ key: "Tất cả", value: "" });
    return setData;
  } catch (err) {
    console.log(err);
    return [];
  }
}

async function getMerchantRequest(params) {
  try {
    const { data } = await api.get(getMerchant, { ...params, limit: 5000 });
    const setData = _.map(data.data, item => ({
      key: item.name,
      value: item.id
    }));
    setData.unshift({ key: "Tất cả", value: "" });
    return setData;
  } catch (err) {
    console.log(err);
    return [];
  }
}

function* fetchPackageRequest(params) {
  try {
    const data = yield call(getPackageRequest, params);
    yield put(fetchPackageSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchPackage() {
  yield takeEvery(GET_PACKAGE, fetchPackageRequest);
}

function* fetchMerchantRequest(params) {
  try {
    const fetchedSticky = yield call(getMerchantRequest, params);
    yield put(fetchMerchantSuccess(fetchedSticky));
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchMerchant() {
  yield takeEvery(GET_LIST_MERCHANT, fetchMerchantRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchPackage), fork(fetchMerchant)]);
}
