import axios from "axios";
import qs from "query-string";
import _ from "lodash";

const instance = axios.create({
  headers: {
    "content-type": "multipart/form-data",
    "Accept-Language": "vn",
  }
});

instance.interceptors.request.use(
  config => {
    const request = config;
    let token = localStorage.getItem("token");
    if (window.token) {
      token = window.token;
    }

    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`
      };
    }

    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  r => r,
  error => Promise.reject(error)
);

const _requestError = err => {
  const status = _.get(err, "response.status");
  switch(status) {
    case 403:
      localStorage.clear();
      window.location.reload();
      return;
  }
  const message = _.get(err, "response.data.error.message");
  const error = _.get(err, "response.data.error") || { message: "" };
  if (typeof message === "string") {
    error.message = message;
  } else {
    error.message = "Whoops! Something went wrong";
  } // eslint-disable-next-line
  throw { error };
};

const get = (endpoints, params, headers) => {
  if (_.isEmpty(headers)) {
    return instance
      .get(`${endpoints}${params ? `?${qs.stringify(params)}` : ``}`)
      .then(res => res)
      .catch(_requestError);
  }
  return instance
    .get(`${endpoints}${params ? `?${qs.stringify(params)}` : ``}`, headers)
    .then(res => res)
    .catch(_requestError);
};

const post = (endpoints, params, headers) => {
  if (_.isEmpty(headers)) {
    return instance
      .post(`${endpoints}`, params)
      .then(res => res)
      .catch(_requestError);
  }
  return instance
    .post(`${endpoints}`, params, headers)
    .then(res => res)
    .catch(_requestError);
};

const patch = (endpoints, params) => {
  return instance
    .patch(`${endpoints}`, params)
    .then(res => res)
    .catch(_requestError);
};
const put = (endpoints, params) => {
  return instance
    .put(`${endpoints}`, params)
    .then(res => res)
    .catch(_requestError);
};
const del = endpoints => {
  return instance
    .delete(`${endpoints}`)
    .then(res => res)
    .catch(_requestError);
};

const changeHeaders = headers => {
  if (typeof headers === "undefined") {
    return;
  }
  const defaultHeaders = _.get(instance, "defaults.headers");
  const cleanHeaders = _.omitBy(
    {
      ...defaultHeaders,
      ...headers
    },
    _.isEmpty
  );
  instance.defaults.headers = cleanHeaders;
};

export default {
  get,
  post,
  patch,
  put,
  delete: del,
  changeHeaders
};
