import React from "react";
import NotificationItem from "./NotificationItem";
import {notifications} from "./data";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";

const AppNotification = () => {
  return (
    <Auxiliary>
      <div className="">
        <h3 className="">Notifications</h3>
        <i className=""/>
      </div>
      <CustomScrollbars className="">
        <ul className="">
          {notifications.map((notification, index) => <NotificationItem key={index}
                                                                        notification={notification}/>)
          }
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  )
};

export default AppNotification;

