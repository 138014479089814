import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  GET_PROFILE
} from "constants/ActionTypes";

export const userSignIn = dataRequest => {
  return {
    type: SIGNIN_USER,
    dataRequest
  };
};

export const userSignInSuccess = dataResult => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: dataResult
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const getProfile = payload => {
  return {
    type: GET_PROFILE,
    payload
  };
};
