import React from "react";
import {Avatar} from "antd";


const NotificationItem = ({notification}) => {
  const {icon, image, title, time} = notification;
  return (

    <li className="">
      <Avatar className=""
              alt={image}
              src={image}/>
      <div className="">
        <p className="">{title}</p>
        <i className={``}/> <span className=""><small>{time}</small></span>
      </div>
    </li>
  );
};

export default NotificationItem;
