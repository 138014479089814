/* eslint-disable */
export const configs = {
  APIs_HEADERS: {
    AUTHORIZATION: "Authorization"
  }
};

export const listRoles = [
  "admin",
  "moderator",
  "merchant",
  "merchant_admin",
  "customer_service",
  "user"
];

export const REGEX_PHONE_NUMBER = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
export const REGEX_EMAIL = /^[a-z][a-z0-9_\.]{0,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/;
export const REGEX_NUMBER = /^[0-9]*$/;
