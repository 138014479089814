import { Layout } from "antd";
import Sidebar from "containers/Sidebar";
import AboveHeader from "containers/Topbar/AboveHeader";
import BelowHeader from "containers/Topbar/BelowHeader";
import HorizontalDefault from "containers/Topbar/HorizontalDefault";
import InsideHeader from "containers/Topbar/InsideHeader";
import NoHeaderNotification from "containers/Topbar/NoHeaderNotification";
import React, { Component } from "react";
import { connect } from "react-redux";
import App from "routes";
import Topbar from "../Topbar";
// import Customizer from "containers/Customizer";
import { footerText } from "util/config";
import { getProfile } from "../../appRedux/actions/Auth";
import api from "../../appRedux/drivers";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { getMe } from "../../constants/api";

export class MainApp extends Component {
  async UNSAFE_componentWillMount() {
    try {
      const { data } = await api.get(getMe);
      this.props.getProfile(data.data);
    } catch (err) {
      console.log("getProfile", err);
    }
  }

  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  getNavStyles = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      // case NAV_STYLE_DARK_HORIZONTAL:
      //   return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  render() {
    const { match, width, navStyle, navCollapsed} = this.props;

    return (
      <Layout>
        <Sidebar/>
        <Layout className="ant-layout">
          <Layout.Header className={`${this.getNavStyles(navStyle)}`}>
          {this.getNavStyles(navStyle)}
            {/* <MenuOutlined onClick={() => this.props.toggleCollapsedSideNav(!navCollapsed)}/> */}
          </Layout.Header>
          <Layout.Content
            className={`${this.getContainerClass(navStyle)} `}
            style={{padding: "1%"}}
          >
            <App match={match} />
            
          </Layout.Content>
          <Layout.Footer style={{backgroundColor: "#fafafa"}}>
              <div>{footerText}</div>
            </Layout.Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings;
  return { width, navStyle };
};
export default connect(mapStateToProps, { getProfile })(MainApp);
