import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess
} from "appRedux/actions/Auth";

const signInUserRequest = async ({
  id,
  username,
  email,
  fullname,
  role,
  money,
  accessToken
}) => {
  return {
    id,
    username,
    email,
    fullname,
    role,
    money,
    token: accessToken.id
  };
};

function* signInUserWithEmailPassword({ dataRequest }) {
  try {
    const dataResult = yield call(signInUserRequest, dataRequest);
    if (dataResult.message) {
      yield put(showAuthMessage(dataResult.message));
    } else {
      localStorage.setItem("token", `${dataResult.token}`);
      yield put(userSignInSuccess(dataResult));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

function* signOut() {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
